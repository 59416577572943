<template>
  <tr style="height: 1.8dvh !important">
    <td class="footer-label col-6">
      <strong>{{ name }} </strong>
    </td>
    <td class="footer-center col-2">:</td>
    <td class="footer-value col-4">
      <span>{{ symbol }}</span>
      <strong>{{ value }}</strong>
    </td>
  </tr>
</template>
<script>
export default {
  name: "Invoice4TableFooter",
  props: {
    name: {},
    value: {},
    symbol: {
      type: String,
      default: "",
    },
  },
};
</script>
<style scoped>
tr {
  height: 3dvh !important;
}

td.footer-label {
  font-size: 2dvh !important;
  text-align: left;
}

td.footer-center {
  font-size: 2dvh !important;
  text-align: center;
}

td.footer-value {
  font-size: 2dvh !important;
  text-align: right;
}
</style>
