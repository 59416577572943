<template>
  <div class="invoice">
    <div class="row">
      <div class="col-7">
        <div class="image">
          <Image_Swiper_Set height="85dvh" width="60dvw" :status="status" />
        </div>
      </div>
      <div class="col-5" style="height: 100dvh !important">
        <div class="row">
          <div class="block">
            <div class="row text-center">
              <div class="name">
                <h2>{{ getBusinessName() }}</h2>
              </div>
            </div>

            <div class="row text-center">
              <div>
                <p class="bold-text address">{{ getLocationAddress() }}</p>
              </div>
            </div>

            <div class="row" style="font-size: 2dvh; text-align-last: right">
              <div class="current-time-container">
                <p class="current-time">{{ currentTime }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="main-table">
          <table
            class="table"
            style="
              text-align: center;
              height: 65dvh !important;
              background: rgb(255, 255, 255);
            "
          >
            <thead style="height: 2dvh !important">
              <tr>
                <th scope="col" class="col-1">No</th>
                <th scope="col" class="col-5">Product Name</th>
                <th scope="col" class="col-2">Qty</th>
                <th scope="col" class="col-2">Unit Price</th>
                <th scope="col" class="col-2">Price</th>
              </tr>
            </thead>
            <tbody
              class="product-line"
              style="height: 42dvh !important"
              ref="productTable"
            >
              <tr v-for="(product, index) in products" :key="product.row_id">
                <th
                  class="col-1"
                  scope="row"
                  style="
                    text-align: center;
                    border-right-color: black;
                    border-right-width: 1px;
                  "
                >
                  {{ index + 1 }}
                </th>
                <td
                  class="col-5"
                  style="
                    text-align: left;
                    border-right-color: black;
                    border-right-width: 1px;
                    text-wrap: normal;
                    white-space: nowrap;
                    overflow: hidden;
                  "
                >
                  {{ product.product_name }}
                </td>
                <td
                  class="col-2"
                  style="
                    text-align: right;
                    border-right-color: black;
                    border-right-width: 1px;
                  "
                >
                  {{ product.quantity }}
                </td>
                <td
                  class="col-2"
                  style="
                    text-align: right;
                    border-right-color: black;
                    border-right-width: 1px;
                  "
                >
                  {{ product.price }}
                </td>
                <td class="col-2" style="text-align: right">
                  {{ product.productTotalPrice.toFixed(2) }}
                </td>
              </tr>
            </tbody>
            <tfoot style="height: 8dvh !important">
              <tr style="height: 2dvh !important">
                <td class="col-12" style="text-align: right">
                  <strong>Total Items : {{ totalItem }}</strong>
                </td>
              </tr>
              <Invoice4TableFooter
                :name="'Sub Total'"
                :value="formatCurrency(totalPrice)"
              />
              <Invoice4TableFooter
                :name="'Discount'"
                :value="formatCurrency(discount)"
                :symbol="'(-)'"
              />
              <Invoice4TableFooter
                :name="'Tax'"
                :value="formatCurrency(tax)"
                :symbol="'(+)'"
              />
            </tfoot>
          </table>
        </div>
        <div class="final_total">
          <strong> Total Price : {{ formatCurrency(finalTotal) }} </strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Image_Swiper_Set from "@/components/Image_Swiper_Set.vue";
import Invoice4TableFooter from "@/themes/Invoice4TableFooter.vue";
import invoiceMixin from "@/service/invoiceMixin";

export default {
  mixins: [invoiceMixin], //common script
  components: {
    Invoice4TableFooter,
    Image_Swiper_Set,
  },
  props: {
    businessBasicDetails: {
      type: Object,
    },
    productsData: {
      type: Array,
    },
  },
  data() {
    return {
      status: "billing",
    };
  },
};
</script>

<style scoped>
.invoice {
  background: linear-gradient(to bottom, #033d8c 20%, #0598d1 50%, #033d8c);
  border-color: rgba(255, 250, 250, 0);
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.image {
  margin-left: 5dvh;
  margin-bottom: 5dvh;
  margin-top: 5dvh;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  border-radius: 30px; /* Adjust the value as desired */
}

.block {
  padding: 5px;
  height: 15dvh;
  margin-top: 3dvh;
  justify-content: center;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.name {
  font-size: 2dvh;
  color: #ffffff;
  text-overflow: ellipsis;
  margin-left: 7px;
  margin-top: 1dvh;
  align-items: center;
  font-weight: bold;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.bold-text {
  margin-left: 6px;
  color: #ffffff;
  align-items: center;
  justify-content: center;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.current-time {
  font-size: 14px;
  color: #ffffff;
  margin-right: 40px;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.main-table {
  margin-right: 2dvw;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  height: 70dvh;
  border-radius: 2rem;
}

table {
  width: 100%;
  table-layout: fixed;
}

thead,
tfoot {
  display: table;
  width: 100%;
  table-layout: fixed;
  position: relative;
  background-color: #ffffff;
}

tbody {
  display: block;
  height: 40dvh;
  overflow-y: auto;
  overflow-x: hidden;
}

tbody.product-line::-webkit-scrollbar {
  width: 5px;
}

tbody.product-line::-webkit-scrollbar-thumb {
  background-color: #888;
}

tbody.product-line::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

tbody.product-line tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

tfoot tr {
  display: table;
  width: 100%;
  table-layout: auto;
}

.final_total {
  font-size: 3dvh;
  margin-left: 2dvw;
  margin-right: 2dvw;
  margin-bottom: 1dvh !important;
  text-align: center;
  color: rgb(255, 255, 255);
  position: relative;
}

.address {
  white-space: pre-line;
}
</style>
