import Echo from "laravel-echo";
window.Pusher = require("pusher-js");

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get("token");
const client_server_url = urlParams.get("server_url");

// window.Pusher.logToConsole = true;

const echo = new Echo({
  broadcaster: "pusher",
  key: process.env.VUE_APP_WEBSOCKETS_KEY,
  // wsHost: process.env.VUE_APP_WEBSOCKETS_SERVER,
  wsPort: process.env.VUE_APP_WEBSOCKETS_PORT || 6001,
  wssPort: process.env.VUE_APP_WEBSOCKETS_PORT || 6001,
  cluster: process.env.VUE_APP_WEBSOCKETS_CLUSTER,
  forceTLS: false,
  disableStats: true,
  authEndpoint: `${client_server_url}/api/broadcasting/auth`,
  auth: {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  },
});

export default echo;
