import { createApp } from "vue";
import App from "./App.vue";

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get("token");
const client_server_url = urlParams.get("server_url");

const app = createApp(App);
app.config.productionTip = false;

app.mixin({
  data() {
    return {
      authToken: token,
      apiBaseURL: client_server_url,
    };
  },
});

app.mount("#app");
