<template>
  <div class="app-container">
    <BaseDisplay
      :businessBasicDetails="businessBasicDetails"
      :client_server_url="apiBaseURL"
      :token="authToken"
    />
  </div>

  <footer class="footer">
    <div class="watermark">
      <strong>Powered by </strong>
      <img
        class="watermark-image"
        src="https://storemate.lk/wp-content/uploads/2022/01/Store-Mate-Logo-1-1.webp"
      />
    </div>
  </footer>
</template>

<script>
import BaseDisplay from "./pages/Base_Display.vue";
import APIService from "@/service/APIService";

export default {
  name: "App",
  components: {
    BaseDisplay,
  },
  data() {
    return {
      businessBasicDetails: {},
    };
  },
  mounted() {
    const event = new KeyboardEvent("keydown", {
      key: "ArrowRight",
      code: "ArrowRight",
      location: 0,
      ctrlKey: false,
      shiftKey: true,
      altKey: false,
      metaKey: true,
      repeat: false,
      isComposing: false,
      charCode: 0,
      keyCode: 39,
      which: 39,
    });

    // Dispatch the key event on a target element
    const targetElement = document.body; // Change this to your desired target element
    targetElement.dispatchEvent(event);

    const businessBasicDetails = new APIService(
      this.authToken,
      this.apiBaseURL,
      "business-basic-details"
    );
    businessBasicDetails
      .getData()
      .then((data) => {
        this.businessBasicDetails = data;
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>
<style>
.app-container {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 5dvh !important;
  height: 5dvh !important;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 1)
  );
}

.watermark {
  position: absolute;
  top: 35%;
  right: 0;
  transform: translate(-30%, -30%);
  font-size: 16px;
  pointer-events: none;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.watermark-image {
  height: 50px;
  width: 50px;
}
</style>
