<template>
  <div v-if="status === 'idle'">
    <Image_Swiper_Set :status="status" />
  </div>
  <div v-else-if="status === 'billing'">
    <Billing_Status
      :businessBasicDetails="businessBasicDetails"
      :productsData="productData"
    />
  </div>
  <div v-else-if="status === 'checkout'">
    <Image_Swiper_Set :status="status" />
  </div>
</template>

<script>
import Image_Swiper_Set from "@/components/Image_Swiper_Set.vue";
import Billing_Status from "@/pages/Billing_Status.vue";
import echo from "@/echos";
import auth_data from "@/auth_data";
export default {
  components: { Billing_Status, Image_Swiper_Set },
  props: {
    businessBasicDetails: {
      type: Object,
    },
    client_server_url: {
      type: String,
    },
    token: {
      type: String,
    },
  },
  data() {
    return {
      images: [],
      currentImageIndex: 0,
      status: "idle",
      productData: [],
      user_id: undefined,
      dbName: undefined,
    };
  },
  computed: {
    currentImage() {
      return this.images.length > 0
        ? this.images[this.currentImageIndex]
        : null;
    },
    altText() {
      return `Image ${this.currentImageIndex + 1}`;
    },
  },
  mounted() {
    // Simulating billing start
    auth_data.then((data) => {
      this.user_id = data.user_id;
      this.dbName = data.db_name;
      this.setupEchoListeners(); // Call the method to setup Echo listeners after getting user_id
    });
  },
  methods: {
    setupEchoListeners() {
      const channel = echo.private(
        `storemate_cus_display.${this.dbName}.${this.user_id}`
      );

      channel.listen("ProductAdd", (e) => {
        this.status = "billing";
        this.productData.push(e.data);
      });

      channel.listen(".customer_display.BillFinalize", () => {
        setTimeout(() => {
          this.status = "checkout";
          this.productData = [];

          // After 5 seconds, change status back to idle
          setTimeout(() => {
            this.status = "idle";
          }, 10000);
        }, 500);
      });
    },
  },
};
</script>

<style scoped>
.idle-status {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.billing-status {
  width: 60%;
  margin: auto;
}

.product-line {
  margin-top: 20px;
}
</style>
