import echo from "@/echos";
import auth_data from "@/auth_data";

export default {
  data() {
    return {
      products: [],
      currentTime: null,
      discount: 0,
      tax: 0,
      subtotal: 0,
      Echos: undefined,
      Channel: undefined,
      user_id: undefined,
      dbName: undefined,
    };
  },
  computed: {
    totalPrice() {
      return this.products.reduce((total, product) => {
        this.subtotal = total + product.productTotalPrice;
        return this.subtotal;
      }, 0);
    },
    totalItem() {
      return this.products.reduce((itemTotal, product) => {
        return parseFloat(itemTotal) + parseFloat(product.quantity);
      }, 0);
    },
    finalTotal() {
      const subtotal = Number(this.subtotal);
      const tax = Number(this.tax);
      const discount = Number(this.discount);

      return subtotal + tax - discount;
    },
  },
  created() {
    this.productAdd(this.productsData[0]);
  },
  mounted() {
    auth_data.then((data) => {
      this.user_id = data.user_id;
      this.dbName = data.db_name;
      this.setupWebSocketListeners();
    });
    this.getCurrentTime();
    this.startTimer();
  },
  methods: {
    formatCurrency(value) {
      const currencySymbol =
        this.businessBasicDetails.data?.basic.currency || "";
      const amount = parseFloat(value).toFixed(2);
      return `${currencySymbol} ${amount}`;
    },
    productAdd(data) {
      if (
        this.products.find((obj) => obj.row_id !== data.row_id) ||
        this.products == ""
      ) {
        data.productTotalPrice = data.price * data.quantity;
        this.products.push(data);
        this.$nextTick(() => {
          this.scrollTableToBottom();
        });
      }
    },
    scrollTableToBottom() {
      const table = this.$refs.productTable;
      if (table) {
        table.scrollTop = table.scrollHeight;
      }
    },
    updateQuantity(rowId, newQuantity) {
      const item = this.products.find((obj) => obj.row_id === rowId);
      if (item) {
        item.productTotalPrice = item.price * newQuantity;
        item.quantity = newQuantity;
      }
    },
    productRemove(rowId) {
      const index = this.products.findIndex((obj) => obj.row_id === rowId);
      if (index >= 0) {
        this.products.splice(index, 1);
      }
    },
    productPriceChange(rowId, newPrice) {
      const unitPrice = newPrice.toFixed(2);
      const item = this.products.find((obj) => obj.row_id === rowId);
      if (item) {
        item.productTotalPrice = item.quantity * unitPrice;
        item.price = unitPrice;
      }
    },
    productLineDiscount(rowId, productTax) {
      const item = this.products.find((obj) => obj.row_id === rowId);
      if (item) {
        item.productTax = productTax;
      }
    },
    getBusinessName() {
      return this.businessBasicDetails.data?.business_name || "";
    },
    getLocationAddress() {
      return this.businessBasicDetails.data?.user_default_location || "";
    },
    getCurrentTime() {
      const currentDate = new Date();
      this.currentTime = currentDate.toLocaleString();
    },
    startTimer() {
      setInterval(() => {
        this.getCurrentTime();
      }, 1000);
    },
    resetInvoiceDetails() {
      this.products = [];
    },
    setupWebSocketListeners() {
      const channel = echo.private(
        `storemate_cus_display.${this.dbName}.${this.user_id}`
      );

      channel.listen("ProductAdd", (e) => {
        this.productAdd(e.data);
      });

      channel.listen(".customer_display.ProductQuantityChange", (e) => {
        this.updateQuantity(e.data.row_id, e.data.current_quantity);
      });

      channel.listen(".customer_display.ProductRowRemove", (e) => {
        this.productRemove(e.data.row_id);
      });

      channel.listen(".customer_display.ProductUnitPriceChange", (e) => {
        this.productPriceChange(e.data.row_id, e.data.current_price);
      });

      channel.listen(".customer_display.ProductLineDiscount", (e) => {
        this.productLineDiscount(e.data.row_id, e.data.line_discount);
      });

      channel.listen(".customer_display.ProductLineDiscount", (e) => {
        this.productTax(e.data.row_id, e.data.productTax);
      });

      channel.listen(".customer_display.ResetInvoiceDetails", () => {
        this.resetInvoiceDetails();
      });

      channel.listen(".customer_display.DiscountAdd", (e) => {
        this.discount = e.data.discount;
      });

      channel.listen(".customer_display.TaxAdd", (e) => {
        this.tax = e.data.tax;
      });
    },
  },
};
