<template>
  <div>
    <img
      v-if="currentImage"
      :src="currentImage"
      :style="{ width: imageWidth, height: imageHeight }"
    />
  </div>
</template>

<script>
import APIService from "@/service/APIService";
const urlParams = new URLSearchParams(window.location.search);
const displayId = urlParams.get("displayId") ?? null;
import defaultDisplayBanner from "/public/images/default_display_banner.jpg";

export default {
  props: {
    width: {
      type: String,
      default: "100dvw", // Default image width if prop is not provided
    },
    height: {
      type: String,
      default: "100dvh", // Default image height if prop is not provided
    },
    status: {
      type: String,
      default: "idle",
    },
  },
  data() {
    return {
      images: [defaultDisplayBanner],
      swiperSpeed: 2000,
      currentImageIndex: 0,
    };
  },
  computed: {
    currentImage() {
      return this.images[this.currentImageIndex];
    },
    imageWidth() {
      return this.width;
    },
    imageHeight() {
      return this.height;
    },
  },
  created() {
    this.startTimer();
  },
  mounted() {
    this.fetchImages();
  },
  beforeUnmount() {
    this.stopTimer();
  },
  methods: {
    startTimer() {
      console.log(this.status);
      this.timer = setInterval(() => {
        this.currentImageIndex =
          (this.currentImageIndex + 1) % this.images.length;
      }, this.swiperSpeed); // Change the interval (in milliseconds) to adjust the swap speed
    },
    stopTimer() {
      clearInterval(this.timer);
    },
    fetchImages() {
      let path = "?status=" + this.status;
      if (displayId != null) {
        path += "&displayId=" + displayId;
      }

      const swiperImageDetails = new APIService(
        this.authToken,
        this.apiBaseURL,
        "customer-display/images" + path
      );
      swiperImageDetails
        .getData()
        .then((data) => {
          this.images = data.data.images;
          this.swiperSpeed = Number(data.data.speed);
          this.stopTimer();
          this.startTimer();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style scoped>
img {
  object-fit: fill;
}
</style>
