import APIService from "@/service/APIService";

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get("token");
const client_server_url = urlParams.get("server_url");

const auth_data = new APIService(token, client_server_url, "user").getData();

export default auth_data.then((data) => {
  return data.data;
});
