<script>
import InvoiceClassic from "@/themes/InvoiceDisplay2.vue";
import InvoiceDark from "@/themes/InvoiceDisplay1.vue";

export default {
  components: {
    InvoiceDark,
    InvoiceClassic,
  },
  props: {
    businessBasicDetails: {
      type: Object,
    },
    productsData: {
      type: Array,
    },
    theme: {
      type: String,
      default: "classic",
    },
  },
};
</script>

<template>
  <div v-if="theme === 'dark'">
    <InvoiceDark
      :businessBasicDetails="businessBasicDetails"
      :products-data="productsData"
    />
  </div>
  <div v-else-if="theme === 'classic'">
    <InvoiceClassic
      :businessBasicDetails="businessBasicDetails"
      :products-data="productsData"
    />
  </div>
</template>

<style scoped>
@import "~bootstrap/dist/css/bootstrap.css";
</style>
